import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Snackbar } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import './connectWallet.scss';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import Alert from "@material-ui/lab/Alert";
import Password from "./containers/password/password";
import Finished from "./containers/finished/finished";
import RecoverySecretPharse from "./containers/recovery_secret_pharse/recovery_secret_pharse";
import { Tooltip } from '@mui/material';
import Account from "./containers/account/account";
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TokenIcon from '@mui/icons-material/Token';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import LogoutIcon from '@mui/icons-material/Logout';
import Summary from "./containers/summary/summary";
import RecentActivity from "./containers/recent-activity/recent-activity";
import WidgetsIcon from '@mui/icons-material/Widgets';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import SecretPharse from "./containers/secret_pharse/secret_pharse";
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AllTransections from "./containers/all-transections/all-transections";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: 520,
    width: 450,
    background: 'rgb(34, 34, 34)',
    borderRadius: 10,
    boxShadow: '0px 1px 15px 2px #333',
    border: '1px solid rgb(50, 50, 50)'
  }
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

const stepperUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

const truncate = (text: any, startChars: number, endChars: number, maxLength: number) => {
  if (text.length > maxLength) {
    var start = text.substring(0, startChars);
    var end = text.substring(text.length - endChars, text.length);
    while ((start.length + end.length) < maxLength) {
      start = start + '.';
    }
    return start + end;
  }
  return text;
}

const walletCode = (string: any) => {
  return truncate(string, 5, 5, 16)
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConnectWallet = () => {
  const classes = useStyles();
  const [isCreateWallet, setIsCreateWallet] = useState(false);
  const [isSecretRecovery, setIsSecretRecovery] = useState(false);
  const [isStepperNextStep, setIsStepperNextStep] = useState(false);
  const [textCode, setTextCode] = useState('benefit grow patient copy claw nose shadow typical purpose clarify traffic error');
  const stepperClasses = stepperUseStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [hasConnectWalletDone, setConnectWalletDone] = useState(false);
  const [hasScreenStatus, setHasScreenStatus] = useState(false);
  const [hasSignedIn, setHasSignedIn] = useState(false);
  const [password, setPassword] = useState('');
  const [selectedTab, setSelectedTab] = useState(1);
  const [isForgotPassword, setForgotPasswordFlag] = useState(false);
  const [forgotPassword, setForgotPassword] = useState('');
  const [allTransections, setAllTransections] = useState([]);
  const [address, setAddress] = useState('');
  const steps = getSteps();
  const [isLoading, setIsloading] = useState(true);
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getSteps() {
    if (!isSecretRecovery)
      return ['Secret Recovery Phrase', 'Create a password', 'finished'];
    else
      return ['First Secret Recovery Phrase', 'Create a password', 'finished'];
  }

  const onCopy = (text: any) => {
    navigator.clipboard.writeText(text);
    setAlertState({
      open: true,
      message: "Text copied successfully!!",
      severity: "info",
    });
  }

  const continueClick = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const OnRecoveryPhase = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const OnAccountImport = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function getStepContent(step: number) {
    if (!isSecretRecovery) {
      switch (step) {
        case 0:
          return <SecretPharse onSaveItClick={(secretPharse:any) => onSaveItClick(secretPharse)}></SecretPharse>;
        case 1:
          return <Password onContinue={() => continueClick()} />;
        case 2:
          return <Finished onFinished={() => onFinished()} />;
        default:
          return 'Unknown step';
      }
    } else {
      switch (step) {
        case 0:
          return <RecoverySecretPharse OnRecoveryPhase={() => OnRecoveryPhase()} />;
        // case 1:
        //   return <Account OnAccountImport={() => OnAccountImport()} />;
        case 1:
          return <Password onContinue={() => continueClick()} />;
        case 2:
          return <Finished onFinished={() => onFinished()} />;
        default:
          return 'Unknown step';
      }
    }

  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCardBack = () => {
    setIsCreateWallet(false);
  }

  const onSaveItClick = (secretPharse:any) => {
    if (countWords(secretPharse) == 12 || countWords(secretPharse) == 24) {
      setIsStepperNextStep(true);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      localStorage.setItem('secretPharse', secretPharse);
    } else {
      setAlertState({
        open: true,
        message: "Invalid secret recovery phrase.",
        severity: "error",
      });
    }
  }

  function countWords(s: any) {
    s = s.replace(/(^\s*)|(\s*$)/gi, "");//exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi, " ");//2 or more space to 1
    s = s.replace(/\n /, "\n"); // exclude newline with a start spacing
    return s.split(' ').filter(function (str: any) { return str != ""; }).length;
    //return s.split(' ').filter(String).length; - this can also be used
  }

  const onSecretClick = () => {
    setIsCreateWallet(true);
    setIsSecretRecovery(true);
    setActiveStep(0);
    setIsStepperNextStep(true);
  }

  const onCreateWallet = () => {
    setIsCreateWallet(true);
    setIsSecretRecovery(false);
    setActiveStep(0);
  }

  const onFinished = () => {
    setIsloading(true);
    let secretPharse = localStorage.getItem('secretPharse');
    let body = {"seed": secretPharse}
    axios.post("https://block.nervchain.xyz/keys-from-seed/", body)
    .then(
      (result:any) => {
        setIsloading(false);
        localStorage.setItem('keys-from-seed', JSON.stringify(result.data));
        setConnectWalletDone(true);
        localStorage.setItem('hasConnectWalletDone', 'yes');
        sessionStorage.setItem('hasSignedIn', 'yes');
        setHasSignedIn(true);
        setAddress(result.data.address);
      },
      (error) => {
        setIsloading(false);
        setAlertState({
          open: true,
          message: "Something went wrong!!",
          severity: "error",
        });
      }
    )
  }

  const passwordChange = (event: any) => {
    setPassword(event.target.value);
  }

  const onUnlock = () => {
    let storePassword = localStorage.getItem('setPassword');
    if (storePassword != password) {
      setAlertState({
        open: true,
        message: "You have enter wrong password.",
        severity: "error",
      });
    } else {
      sessionStorage.setItem('hasSignedIn', 'yes');
      setHasSignedIn(true);
    }
  }

  const getAllTransections = () => {
    let date = new Date();
    axios.get(`https://block.nervchain.xyz/recent-transactions/?random=${date}`)
      .then(
        (result) => {
          setAllTransections(result.data);
        },
        (error) => {
          
        }
    )
  }

  useEffect(() => {
    setIsloading(true);
    (async function () {
      try {
        let getWalletStatus = await localStorage.getItem('hasConnectWalletDone');
        if (getWalletStatus == 'yes') {
          setConnectWalletDone(true);
          let walletDetails:any = await JSON.parse(localStorage.getItem('keys-from-seed') || '{}');
          setAddress(walletDetails.address);
        }
        setHasScreenStatus(true);

        let hasSignedInStatus = await sessionStorage.getItem('hasSignedIn');
        if (hasSignedInStatus == 'yes')
          setHasSignedIn(true);

        getAllTransections();
        setIsloading(false);
      } catch (e) {
        console.error(e);
        setIsloading(false);
      }
    })();

  }, [])

  const getContent = () => {
    if (selectedTab == 1) {
      return <Summary></Summary>
    } else if (selectedTab == 2) {
      return (<div>
        <Card style={{height: "454px", overflowY: "scroll", background: "rgba(0,0,0,0.1)"}}>
          <div className="gallery">

            <div className="gallery-item">
            <a href="https://market.nervchain.xyz">
              <img className="gallery-image" src="assets/img/nft.jpg" alt="person writing in a notebook beside by an iPad, laptop, printed photos, spectacles, and a cup of coffee on a saucer" />
            </a>
            </div>

            <div className="gallery-item">
            <a href="https://market.nervchain.xyz">
              <img className="gallery-image" src="assets/img/nft6.jpg" alt="sunset behind San Francisco city skyline" />
            </a>
            </div>

            <div className="gallery-item">
            <a href="https://market.nervchain.xyz">
              <img className="gallery-image" src="assets/img/nft3.jfif" alt="people holding umbrellas on a busy street at night lit by street lights and illuminated signs in Tokyo, Japan" />
            </a>
            </div>

            <div className="gallery-item">
            <a href="https://market.nervchain.xyz">
              <img className="gallery-image" src="assets/img/nft4.jpg" alt="car interior from central back seat position showing driver and blurred view through windscreen of a busy road at night" />
            </a>
            </div>

            <div className="gallery-item">
            <a href="https://market.nervchain.xyz">
              <img className="gallery-image" src="assets/img/nft5.jpg" alt="back view of woman wearing a backpack and beanie waiting to cross the road on a busy street at night in New York City, USA" />
            </a>
            </div>

            <div className="gallery-item">
            <a href="https://market.nervchain.xyz">
              <img className="gallery-image" src="assets/img/nft1.jpg" alt="man wearing a black jacket, white shirt, blue jeans, and brown boots, playing a white electric guitar while sitting on an amp" />
            </a>
            </div>

            </div>
        </Card>
      </div>)
    } else if (selectedTab == 3) {
      return <RecentActivity />
    }
  }

  const exitFromWallet = () => {
    localStorage.removeItem('secretPharse');
    setConnectWalletDone(false);
    localStorage.removeItem('hasConnectWalletDone');
    sessionStorage.removeItem('hasSignedIn');
    setHasSignedIn(false);
    localStorage.removeItem('setPassword');
    localStorage.removeItem('keys-from-seed');
    window.location.reload();
  }

  const onForgotPassword = () => {
    exitFromWallet();
    // setForgotPasswordFlag(true)
  }

  const forgotPasswordChange = (event: any) => {
    setForgotPassword(event.target.value);
  }

  const onForgotPasswordSubmit = () => {
    sessionStorage.setItem('hasSignedIn', 'yes');
    localStorage.setItem('setPassword', forgotPassword);
    setHasSignedIn(true);
  }

  const [connection, setConnection] = React.useState('testnet');
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setConnection(event.target.value as string);
  };

  return (
    <main className="main-card">
      <div className="main-inner">
      {isLoading ? (
        <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!hasConnectWalletDone && hasScreenStatus ? (
            <div className="main-card-inner">
              {!isCreateWallet ? (
                <Card className="rootClasses">
                  <CardContent className="first_card_content">
                    <div className="first_card_wrapper">
                      <img src="assets/img/logo.png" alt="" className="logo__img" />
                      <h1>Lil Wallet</h1>
                      <p>A crypto wallet reimagined for NFTs and Gaming</p>
                    </div>
                  </CardContent>
                  <CardActions className="first_card_action">
                    <Button variant="contained" color="primary" onClick={() => onCreateWallet()}>
                      Create New Wallet
                    </Button>
                    <Button variant="contained" onClick={() => onSecretClick()} style={{ marginTop: '15px' }}>
                      Use Secret Recovery Phrase
                    </Button>
                  </CardActions>
                </Card>
              ) : (
                <Card className="rootClasses">
                  <CardContent className="second_card_content" style={{height: "100%"}}>
                    <div className={stepperClasses.root}>
                      <div>
                        {!isStepperNextStep ? (
                          <Button onClick={handleCardBack}>
                            Back
                          </Button>
                        ) : (
                          <Button disabled={activeStep === 0} onClick={handleBack}>
                            Back
                          </Button>
                        )}
                      </div>
                      <Stepper className="stepper-heading" alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </div>

                    <div style={{height: "100%"}}>
                      {getStepContent(activeStep)}
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>
          ) : (
            <div className="wallet-container-back">
              <div className="back-trans">
                <div className="drop-down">
                  <img src="assets/img/nerv1.png"></img>
                  <FormControl className="drop-down-btn" variant="outlined">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={connection}
                      onChange={handleChange}
                    >
                      <MenuItem value="testnet">Testnet</MenuItem>
                      <MenuItem value="mainnet">Mainnet</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="wallet-container">
                  {!hasSignedIn ? (
                    <section className="login-section" style={{ height: '100%' }}>
                      <header className="login_header">
                        <p>Lil Wallet</p>
                      </header>
                      <div style={{ padding: "20px", height: '100%' }}>

                        <div className="login-content">
                          <img src="assets/img/logo.png" alt="" className="login_logo" />
                          <h1 style={{ marginTop: "20px" }}>Enter your password</h1>
                          {!isForgotPassword ? (
                            <TextField style={{ width: "100%" }} id="outlined-basic" label="Password" variant="outlined"
                              type="password"
                              name="password"
                              required={true}
                              onChange={(event) => passwordChange(event)} />
                          ) : (
                            <TextField style={{ width: "100%" }} id="outlined-basic" label="Enter new password" variant="outlined"
                              type="password"
                              name="password"
                              required={true}
                              onChange={(event) => forgotPasswordChange(event)} />
                          )}

                          <p style={{ cursor: 'pointer', width: "100%", textAlign: 'right' }} onClick={onForgotPassword}>Forgot Password</p>
                        </div>
                          <Button color="primary" style={{ marginTop: "20px" }} onClick={onUnlock} disabled={!password} variant="contained">Unlock</Button>
                        {/* {!isForgotPassword ? (
                          <Button color="primary" style={{ marginTop: "20px" }} onClick={onUnlock} disabled={!password} variant="contained">Unlock</Button>
                        ) : (
                          <Button color="primary" style={{ marginTop: "20px" }} onClick={onForgotPasswordSubmit} disabled={!forgotPassword} variant="contained">Submit</Button>
                        )} */}

                      </div>

                    </section>
                  ) : (
                    <div className="wallet-wrapper">
                      <header className="wallet-header">
                        Lil Wallet &#160;
                        <Tooltip title="Copy to clipboard" arrow>
                          <span onClick={() => onCopy(address)}> ({walletCode(address)})</span>
                        </Tooltip>
                      </header>
                      <section style={{ flexGrow: "1" }}>
                        {getContent()}
                      </section>
                      <footer className="footer-tab">
                        <IconButton aria-label="Wallet Summary" onClick={() => setSelectedTab(1)} className={selectedTab == 1 ? "active-btn" : ''} component="span">
                          <Tooltip title="Wallet Summary" arrow>
                            <MonetizationOnIcon />
                          </Tooltip>
                        </IconButton>
                        <IconButton aria-label="View NFT" onClick={() => setSelectedTab(2)} className={selectedTab == 2 ? "active-btn" : ''} component="span">
                          <Tooltip title="View NFT" arrow>
                            <WidgetsIcon />
                          </Tooltip>
                        </IconButton>
                        <IconButton aria-label="Recent Activity" onClick={() => setSelectedTab(3)} className={selectedTab == 3 ? "active-btn" : ''} component="span">
                          <Tooltip title="Recent Activity" arrow>
                            <CompareArrowsIcon />
                          </Tooltip>
                        </IconButton>
                        <IconButton onClick={() => handleClickOpen()} aria-label="Exit from wallet" component="span">
                          <Tooltip title="Exit from wallet" arrow>
                            <LogoutIcon />
                          </Tooltip>
                        </IconButton>

                      </footer>

                      <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{
                          style: {
                            backgroundColor: '#000',
                            border: "1px solid #fff"
                          },
                        }}
                      >
                        <DialogTitle>{"Are you sure you want to exit from wallet?"}</DialogTitle>
                        <DialogActions>
                          <Button onClick={handleClose}>Cancel</Button>
                          <Button onClick={exitFromWallet}>Yes</Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  )}
                </div>
                
                
                {allTransections.length > 0 ? (
                  <AllTransections transections={allTransections} />
                ): (
                  <img src="assets/img/Logo-02.png" alt="" className="middle_logo" />
                )}
              
              </div>
            </div>
          )}
        </>
      )}



      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
      <footer className='footer-secion'>
        <div className="footer__copy">© Nerv Protocol. All rigths reserved 2022</div>
        <div className="footer__social">
            <a href="https://twitter.com/NervChain" target="_blank" className="footer__social-link">
                <i className='bx bxl-twitter' ></i>
            </a>
            <a href="https://t.me/nervchain" target="_blank" className="footer__social-link">
              <i className="bx bxl-telegram" aria-hidden="true"></i>
            </a>
            <a href="mailto:hello@nervchain.xyz" className="footer__social-link">
              <i className='bx bx-mail-send'></i>
            </a>
            <a href="https://medium.com/@NervChain" target="_blank" className="footer__social-link">
                <i className='bx bxl-medium' ></i>
            </a>
        </div>
      </footer>
      </div>

      
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

export default ConnectWallet;
