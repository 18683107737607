import React, { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import './secret_pharse.scss';
import Alert from "@material-ui/lab/Alert";
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const SecretPharse = (props: any) => {
  const [isLoading, setIsloading] = useState(true);
  const [textCode, setTextCode] = useState('');

  const onCopy = (text: any) => {
    navigator.clipboard.writeText(text);
    setAlertState({
      open: true,
      message: "Text copied successfully!!",
      severity: "info",
    });
  }

  const getSeed = () => {
    let date = new Date();
    axios.get(`https://block.nervchain.xyz/get-seed?random=${date}`)
      .then(
        (result) => {
          setIsloading(false);
          setTextCode(result.data.seed);
        },
        (error) => {
          setAlertState({
            open: true,
            message: "Something went wrong!!",
            severity: "error",
          });
          setIsloading(false);
        }
    )
  }

  useEffect(()=> {
    if(isLoading == true) {
      getSeed();
    }
  }, [isLoading])

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  return (
    <>
    {isLoading ? (
      <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
        <CircularProgress />
      </div>
    ) : (
      <div className="recovery-phase-container">
        <h1>Secret Recovery Phrase</h1>
        <p>
          This is the only way you will be able to recover your account. Please store it somewhere safe!
        </p>
        <div className="text-copy-section">
          <textarea id="textarea" readOnly value={textCode}></textarea>
          <p> </p>
          <Button variant="contained" color="primary" onClick={() => onCopy(textCode)}>
            Copy
          </Button>
        </div>
        <CardActions className="second_card_action">
          <Button variant="contained" color="primary" onClick={() => props.onSaveItClick(textCode)}>
            OK, I saved it somewhere
          </Button>
        </CardActions>

        <Snackbar
          open={alertState.open}
          autoHideDuration={6000}
          onClose={() => setAlertState({ ...alertState, open: false })}
          >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>
      </div>
    )}
    </>  
  );
  
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

export default React.memo(SecretPharse);

