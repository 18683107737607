import { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import './summary.scss';
import Alert from "@material-ui/lab/Alert";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import QRCode from 'react-qr-code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CardContent from '@mui/material/CardContent';

const Summary = (props: any) => {
  const [walletPrice, setWalletPrice] = useState(0);
  const [recentPrice, setRecentPrice] = useState(2);
  const [barSize] = useState(160);
  const [depositeCode, setDepositeCode] = useState('');
  const [contentRender, setContentRender] = useState('main');
  const [isLoading, setIsloading] = useState(false);
  const [recipientAddress, setRecipientAddress] = useState('');
  const [amount, setAmount] = useState<any>('');
  const [amountErrorMessage, setAmountErrorMessage] = useState('');
  const [recipientErrorMessage, setRecipientErrorMessage] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const copyCode = () => {
    navigator.clipboard.writeText(depositeCode);
    setAlertState({
      open: true,
      message: "Code copied successfully!!",
      severity: "success",
    });
  }

  useEffect(()=> {
    setIsloading(true);
    (async function () {
      try {
        let walletDetails = await JSON.parse(localStorage.getItem('keys-from-seed') || '{}');
        setDepositeCode(walletDetails.address);
        let body = {"address": walletDetails.address}
        axios.post("https://block.nervchain.xyz/get-wallet-balance/", body)
        .then(
          (result:any) => {
            setIsloading(false);
            setWalletPrice(result.data.balance);
          },
          (error) => {
            setAlertState({
              open: true,
              message: "Something went wrong!!",
              severity: "error",
            });
            setIsloading(false);
          }
        )
      } catch (e) {
        console.error(e);
        setIsloading(false);
      }
    })();
    
  }, [])

  const onRecipientAddressChange = (event:any) => {
    setRecipientAddress(event.target.value);
    if(event.target.value.substring(0, 2) != '0x') {
      setIsSubmitDisabled(true);
      setRecipientErrorMessage('Invalid NRV address');
    } else {
      setRecipientErrorMessage('');
      setIsSubmitDisabled(false);
    }
  }

  const onAmountChange = (event:any) => {
    setAmount(event.target.value);
    if(event.target.value > walletPrice || event.target.value == 0) {
      setIsSubmitDisabled(true);
      setAmountErrorMessage('Insufficient balance');
    } else {
      setAmountErrorMessage('');
      setIsSubmitDisabled(false);
    }
  }

  const submitNAVForm = async () => {
    setIsloading(true);
    let token = await JSON.parse(localStorage.getItem('keys-from-seed') || '{}');
    let body = {token: token.privkey, from_address: token.address, to_address: recipientAddress, amount: parseFloat(amount)};
    axios.post("https://block.nervchain.xyz/send-tokens/", body)
    .then(
      (result:any) => {
        setIsloading(false);
        if(result.data.status == 'failure') {
          setAmount('');
          setRecipientAddress('');
          if(result.data.info == 'Address Invalid') {
            setAmountErrorMessage('Invalid NRV address!!');
            setIsSubmitDisabled(true);
          }
        } else {
          setContentRender('main');
          playSuccessSound();
        }
      },
      (error) => {
        setAlertState({
          open: true,
          message: "Something went wrong!!",
          severity: "error",
        });
        setIsloading(false);
      }
    )
  }

  const onCancel = () => {
    setContentRender('main');
  }

  const sendPopup = () => {
    setContentRender('sendPopup');
    setRecipientAddress('');
    setAmount('');
    setIsSubmitDisabled(true);
  }

  var context = new AudioContext();
  // Play oscillators at certain frequency and for a certain time
  var playNote = function (frequency:any, startTime:any, duration:any) {
  var osc1 = context.createOscillator(),
      osc2 = context.createOscillator(),
      volume = context.createGain();

  // Set oscillator wave type
  osc1.type = 'triangle';
  osc2.type = 'triangle';

  volume.gain.value = 0.1;    

  // Set up node routing
  osc1.connect(volume);
  osc2.connect(volume);
  volume.connect(context.destination);

  // Detune oscillators for chorus effect
  osc1.frequency.value = frequency + 1;
  osc2.frequency.value = frequency - 2;


  // Fade out
  volume.gain.setValueAtTime(0.1, startTime + duration - 0.05);
  volume.gain.linearRampToValueAtTime(0, startTime + duration);


  // Start oscillators
  osc1.start(startTime);
  osc2.start(startTime);

  // Stop oscillators
  osc1.stop(startTime + duration);
  osc2.stop(startTime + duration);
};

var playSuccessSound = function () {
  // Play a 'B' now that lasts for 0.116 seconds
  playNote(493.883, context.currentTime, 0.116);

  // Play an 'E' just as the previous note finishes, that lasts for 0.232 seconds
  playNote(659.255, context.currentTime + 0.116, 0.232);
};


  const getSummaryContent = () => {
    if(contentRender == 'main') {
      return (
        <div style={{padding: "10px"}}>
          <h1>${walletPrice}</h1>
          {/* <h2 className={recentPrice > 0 ? 'postivePrice' : 'negativePrice'}>${recentPrice}</h2> */}
          <div className="btn-wrapper">
            <Button onClick={() => setContentRender('depositePopup')} variant="contained">Deposit</Button>
            <Button variant="contained" onClick={() => sendPopup()}>Send</Button>
          </div>

          <Card className="card-style" variant="outlined" onClick={() => sendPopup()}>
            <CardContent className="card-content">
              <img src="assets/img/logo.png"></img>
              <div className="card-details">
                <div>
                  <div style={{fontWeight: "600", color:"#fff"}}>Nerv</div>
                  <div style={{marginTop: "5px", color:"gray"}}>{walletPrice} NRV</div>
                </div>
                
              </div>
            </CardContent>
          </Card>

        </div>
      )
    } else if (contentRender == 'depositePopup') {
      return (
        <div className="onDeposite">
          <div className="close_icon">
            <CloseIcon style={{cursor:"pointer"}} onClick={() => setContentRender('main')} />
          </div>
          <img src="assets/img/logo.png" alt="" className="logo__img" />
          <div style={{background: "#fff", padding:"10px", marginTop: "20px"}}>
            <QRCode size={barSize} value={depositeCode} />
          </div>
          
          <div className="code-div">
            <div>
              {depositeCode}
            </div>
            <ContentCopyIcon style={{cursor:"pointer"}} onClick={copyCode} />
          </div>
        </div>
      )
    } else if (contentRender == 'sendPopup') {
      return (
        <div style={{display:"flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
          <h2 style={{textAlign: "center", marginTop: "10px"}}>Send NRV</h2>
          <img src="assets/img/logo.png" alt="" className="logo__img" />
          <form style={{padding: "10px", marginTop:"20px", width: "100%"}}>
            <TextField style={{width: "100%"}} className="input-filed" id="outlined-basic" label="Recipient's NRV address" variant="outlined"
              type="text"
              name="recipient_address"
              required={true}
              onChange={(event) => onRecipientAddressChange(event)}
            />
           <p style={{color: "red"}}>{recipientErrorMessage}</p>
            <TextField style={{width: "100%", marginTop: "10px"}} className="input-filed" id="outlined-basic" label="Amount" variant="outlined"
              type='number'
              name="amount"
              required={true}
              onChange={(event) => onAmountChange(event)}
            />
            <p style={{color: "red"}}>{amountErrorMessage}</p>

            <div className="btn-container">
              <Button onClick={onCancel} variant="contained">
                Cancel
              </Button> 
              <Button className="snedBtn" disabled={recipientAddress == '' || amount == '' || isSubmitDisabled } onClick={submitNAVForm} variant="contained" color="primary">
                Send
              </Button> 
            </div>
            
          </form> 
        </div>
      )
    }
  }

  return (
    <div className="summary-container">
       {isLoading ? (
        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {getSummaryContent()}
        </>
      )}
    
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>

    </div>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}


export default Summary;

