import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from '../App';
import { createTheme, ThemeProvider } from "@material-ui/core";
import WalletDetails from "../containers/wallet-details/wallet-details";

const theme = createTheme({
  palette: {
    type: 'dark',
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: 'flex-start',
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: '12px 16px',
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});


function Routess() {

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/"
            element={<App />}
          />
          <Route path="account/:address"
            element={<WalletDetails />}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default Routess;

