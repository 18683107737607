import { useState } from "react";
import { Snackbar } from "@material-ui/core";
import './recovery_secret_pharse.scss';
import Alert from "@material-ui/lab/Alert";
import Button from '@material-ui/core/Button';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';

const RecoverySecretPharse = (props: any) => {
  const [textCode, setTextCode] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const onRecoveryClick = () => {    
    if(countWords(textCode) == 12 || countWords(textCode) == 24) {
      setIsloading(true);
      let body = {"seed": textCode}
      axios.post("https://block.nervchain.xyz/keys-from-seed/", body)
      .then(
        (result) => {
          setIsloading(false);
          localStorage.setItem('secretPharse', textCode);
          props.OnRecoveryPhase();
          localStorage.setItem('keys-from-seed', JSON.stringify(result.data));
        },
        (error) => {
          setAlertState({
            open: true,
            message: "Something went wrong!!",
            severity: "error",
          });
          setIsloading(false);
        }
      )
      
    } else {
      setAlertState({
        open: true,
        message: "Invalid secret recovery phrase.",
        severity: "error",
      });
    }
  }

  function countWords(s:any){
    s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
    s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
    return s.split(' ').filter(function(str:any){return str!="";}).length;
    //return s.split(' ').filter(String).length; - this can also be used
}

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });


  const textChange = (event:any) => {
    setTextCode(event.target.value);
  }

  return (
    <div className="recovery-phase-container">
      {isLoading ? (
      <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <h1>Secret Recovery Phrase</h1>
          <p>
            Restore an existing wallet with your 12 or 24-word secret recovery phrase
          </p>
          <div className="text-copy-section">
            <textarea style={{width: "100%"}} value={textCode} onChange={(event) => textChange(event)}></textarea>
          </div>
          <div className="second_card_action">
            <Button style={{marginTop: "30px"}} variant="contained" color="primary" onClick={() => onRecoveryClick()}>
              Import secret recovery phrase
            </Button>
          </div>
        </>
      )}

      

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
        >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

export default RecoverySecretPharse;

