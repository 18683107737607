import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { Snackbar } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import './wallet-details.scss';
import '../../connectWallet.scss';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import Alert from "@material-ui/lab/Alert";
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TokenIcon from '@mui/icons-material/Token';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import LogoutIcon from '@mui/icons-material/Logout';
import WidgetsIcon from '@mui/icons-material/Widgets';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';

interface Column {
  id: 'transaction_id' | 'from_address' | 'to_address' | 'gas_subsidizer' | 'on_block' | 'amount' | 'created_at';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any) => any;
}

const truncate = (text: any, startChars: number, endChars: number, maxLength: number) => {
  if (text.length > maxLength) {
    var start = text.substring(0, startChars);
    var end = text.substring(text.length - endChars, text.length);
    while ((start.length + end.length) < maxLength) {
      start = start + '.';
    }
    return start + end;
  }
  return text;
}

const columns: readonly Column[] = [
  { id: 'transaction_id', label: 'Transaction Id', minWidth: 170, format: (value) => truncate(value, 5, 5, 16) },
  { id: 'from_address', label: 'From Address', minWidth: 100 },
  {
    id: 'to_address',
    label: 'To Address',
    minWidth: 170,
    align: 'right',
    format: (value) => truncate(value, 5, 5, 16),
  },
  {
    id: 'gas_subsidizer',
    label: 'Gas Subsidizer',
    minWidth: 170,
    align: 'right',
    format: (value) => truncate(value, 5, 5, 16),
  },
  {
    id: 'on_block',
    label: 'On Block',
    minWidth: 170,
    align: 'right',
    format: (value) => value
  },
  {
    id: 'amount',
    label: 'Amount',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'created_at',
    label: 'Confirmed at',
    minWidth: 170,
    align: 'right',
    format: (value) => value
  }
];

interface Data {
  transaction_id: string;
  from_address: string;
  to_address: string;
  gas_subsidizer: string;
  on_block: number;
  amount: string;
  created_at: string;
}

function createData(
  transaction_id: string,
  from_address: string,
  to_address: string,
  gas_subsidizer: string,
  on_block: number,
  amount: string,
  created_at: string

): Data {
  return { transaction_id, from_address, to_address, gas_subsidizer, on_block, amount, created_at };
}

const rows: any = [];

const WalletDetails = (props: any) => {
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [walletTransections, setWalletTransections] = useState([]);
  const [walletSummary, setWalletSummary]: any = useState({});

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function getSummary() {
    let urlParam = window.location.pathname.split('/')[2];
    let body = { "address": `${urlParam}` }
    axios.post("https://block.nervchain.xyz/get-wallet-balance/", body)
      .then(
        (result: any) => {
          setIsloading(false);
          setWalletSummary(result.data);
        },
        (error) => {
          setAlertState({
            open: true,
            message: "Something went wrong!!",
            severity: "error",
          });
          setIsloading(false);
        }
      )
  }

  useEffect(() => {
    let urlParam = window.location.pathname.split('/')[2];
    setIsloading(true);
    (async function () {
      try {

        let date = new Date();
        axios.get(`https://block.nervchain.xyz/wallet-transactions/${urlParam}/?random=${(Math.random() + 1).toString(36).substring(7)}`)
          .then(
            (result: any) => {
              setWalletTransections(result.data);
              if (result.data.length > 0) {
                for (let transection of result.data) {
                  rows.push(
                    createData(truncate(transection.transaction_id, 5, 5, 16), truncate(transection.from_address, 5, 5, 16), truncate(transection.to_address, 5, 5, 16), truncate(transection.gas_subsidizer, 5, 5, 16), transection.on_block, parseFloat(transection.amount).toFixed(2), transection.created_at)
                  )
                }
              }

              setIsloading(false);
              getSummary();
            },
            (error) => {
              setAlertState({
                open: true,
                message: "Something went wrong!!",
                severity: "error",
              });
              setIsloading(false);
              getSummary();
            }
          )

      } catch (e) {
        setIsloading(false);
      }
    })();

  }, [])

  return (
    <div className="wallet-details-container">
      <div className="back-trans">
        <img className="wallet-logo" src="../assets/img/nerv1.png"></img>
        {isLoading && walletSummary ? (
          <Box className="loader-box" sx={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
            <CircularProgress />
          </Box>
        ) : (
          <div className="trans-container">
            <h1 style={{textAlign:'center'}}>Wallet Transactions</h1>
            {walletSummary &&
              <div className="wallet-details">
                {walletSummary.address &&
                  <div className="wallet-table">
                    <span className="title">Account Name : </span>
                    <span>{truncate(walletSummary.address, 5, 5, 16)}</span>
                  </div>
                }

                <div className="wallet-table">
                  <span className="title">Balance : </span>
                  <span>{walletSummary?.balance}</span>
                </div>
              </div>
            }

            {walletTransections.length > 0 ? (
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer className="table-container" sx={{ maxHeight: 400, minHeight: 380 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={(Math.random() + 1).toString(36).substring(7)}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row: any) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={(Math.random() + 1).toString(36).substring(7)}>
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={(Math.random() + 1).toString(36).substring(7)} align={column.align}>
                                    {column.id != 'created_at' ? (
                                      value
                                    ) : (
                                      <Moment fromNow>{value}</Moment>
                                    )}

                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <div>
                <h3>No Transections available yet!!</h3>
              </div>
            )}

          </div>

        )}

      <footer className='footer-secion'>
        <div className="footer__copy">© Nerv Protocol. All rigths reserved 2022</div>
        <div className="footer__social">
            <a href="https://twitter.com/NervChain" target="_blank" className="footer__social-link">
                <i className='bx bxl-twitter' ></i>
            </a>
            <a href="https://t.me/nervchain" target="_blank" className="footer__social-link">
              <i className="bx bxl-telegram" aria-hidden="true"></i>
            </a>
            <a href="mailto:hello@nervchain.xyz" className="footer__social-link">
              <i className='bx bx-mail-send'></i>
            </a>
            <a href="https://medium.com/@NervChain" target="_blank" className="footer__social-link">
                <i className='bx bxl-medium' ></i>
            </a>
        </div>
      </footer>

      </div>


      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>

    </div>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}


export default WalletDetails;

