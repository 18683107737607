import { useEffect, useState } from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './password.scss';

const Password = (props:any) => {
  const [password, setPassword] = useState('');
  const [pwconfirm, setPwconfirm] = useState('');
  const [isAgree, setIsAgree] = useState(false);
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const submitForm = () => {
    let isFormValid = false;
    if(password == '' || pwconfirm == '' || isAgree == false) {
      setAlertState({
        open: true,
        message: "Form is not valid. Please fill up all fields.",
        severity: "error",
      });
      isFormValid = false;
      return false;
    } else {
      if (pwconfirm !== password) {
        setAlertState({
          open: true,
          message: "Password & Confirm Password is not same.",
          severity: "error",
        });
      } else {
        isFormValid = true;
      }
    }

    if(isFormValid) {
      localStorage.setItem('setPassword', password);
      props.onContinue();
    }
   
  }

  const passwordChange = (event:any) => {
    setPassword(event.target.value);
  }

  const pwHandleChange = (event: any) => {
    setPwconfirm(event.target.value);
  }

  const termChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgree(event.target.checked);
  }

  

  return (
    <div className="password-container">
      <h1>Create a password</h1>
      <p>
      You will use this to unlock your wallet
      </p>
      <form>
        <TextField className="input-filed" id="outlined-basic" label="Password" variant="outlined"
          type="password"
          name="password"
          required={true}
          onChange={(event) => passwordChange(event)}
        />
        <TextField className="input-filed" id="outlined-basic" label="Confirm password" variant="outlined"
          type='password'
          name="pwconfirm"
          required={true}
          onChange={(event) => pwHandleChange(event)}
        />

      <FormControlLabel className="agree-input"
        control={<Checkbox
          color="primary"
          value={isAgree}
          onChange={termChange}
        />}
        label="I agree to keep password safe"
      />
        <Button className="signUpSubmit" disabled={password == '' || pwconfirm == '' || isAgree == false} onClick={submitForm} variant="contained" color="primary">
          Continue
        </Button> 
      </form>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>

    </div>
  );
};

export default Password;

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}
